<template>
  <div>
    <headerNav activeIndex="6"></headerNav>
    <div class="joinBody">
      <div class="joinImg"></div>
      <div class="breadCrumbs">首页<span style="color:#999999"> > 生态合作</span></div>
      <div class="joinDiv">
        <div class="divBody">
          <img  src="../../assets/img/joinDiv1.png" style="width:70px;height:60px"/>
          <div class="joinText">商务合作</div>
          <div class="joinSpan">拥有商机资源的合作伙伴，与链动云签订合作协议。伙伴提供商机，链动云负责销售、售前和交付，对于成交销售，按照协议分佣。</div>
          <div class="joinbtn" @click="joinbtnClick">与我们合作</div>
        </div>
        <div class="divBody">
          <img  src="../../assets/img/joinDiv2.png" style="width:62px;height:60px"/>
          <div class="joinText">销售合作</div>
          <div class="joinSpan">拥有客户资源的销售合作伙伴，与链动云签订销售合作协议。伙伴提供商机，完成销售。链动云负责售前和交付。对于成交销售，按照协议分佣。</div>
          <div class="joinbtn">与我们合作</div>
        </div>
        <div class="divBody">
          <img  src="../../assets/img/joinDiv3.png" style="width:70px;height:60px"/>
          <div class="joinText">咨询合作</div>
          <div class="joinSpan">数字化领域的友商，与链动云联合为客户提供更完整的解决方案，互相推介产品。双方签署解决方案合作协议，对于成交销售，按照协议分佣。</div>
          <div class="joinbtn">与我们合作</div>
        </div>
      </div>
      <div class="joinForm"  id="sectionForm">
        <div class="formDiv">
          <div class="formText"><div class="xian"></div>合作热线：180-7313-1810</div>
          <div class="formSpan" >填写正确的联系方式，我们会尽快联系您</div>
          <el-form  ref="form" :model="form" label-width="130px" label-position="left">
            <el-form-item label="您的姓名：">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="备注（选填）：" >
              <el-input  :autosize="{ minRows: 5}" type="textarea"  resize="none" v-model="form.remark"></el-input>
            </el-form-item>
          </el-form>
          <div class="formBtn"  @click="submitted">提交</div>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue";
import { mapState, mapActions } from "vuex";
export default {
  name:'contact',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      form:{
        name:'',
        phone:'',
        email:'',
        remark:'',
      },
      rules: {

      }
    };
  },
  methods: {
    submitted(){
      if(!this.form.name){
        this.$message({
          message: '请输入您的姓名!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.phone){
        this.$message({
          message: '请输入您的联系方式!',
          type: 'warning'
        });
        return;
      }
      if(!this.form.email){
        this.$message({
          message: '请输入您的电子邮箱!',
          type: 'warning'
        });
        return;
      }
      this.form.name = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.remark = ''
      this.$message({
        message: '提交成功!',
        type: 'success'
      });
    },
    joinbtnClick(){
      const element = document.getElementById('sectionForm');
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    },
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
.joinBody{
  width:100%;
  min-height: 800px;
  .joinImg{
    width:100%;
    height:427px;
    background:url("../../assets/img/joinPageImg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .breadCrumbs{
    height: 90px;
    width:1200px;
    margin:0 auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #4C6DFD;
    line-height: 90px;
    text-align: left;
    font-style: normal;
  }
  .joinDiv{
    width:1200px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 82px;
    .divBody{
      width: 374px;
      height: 420px;
      background: #FFFFFF;
      box-shadow: 0px 0px 40px 0px #EFF6FB;
      border-radius: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      .joinText{
        margin-top: 40px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #010101;
        line-height: 40px;
        font-style: normal;
      }
      .joinSpan{
        width: 303px;
        margin-top: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #484848;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }
      .joinbtn{
        width: 120px;
        height: 40px;
        background: #4C6DFD;
        border-radius: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 40px;
        font-style: normal;
        text-align: center;
        position: absolute;
        bottom:40px;
        user-select: none;
        cursor:pointer;
      }
      .joinbtn:hover{
        background: #7D93F5;
        border-radius: 8px;
      }
      img{
        margin-top: 52px;
      }
    }
  }
  .joinForm{
    width:1500px;
    height: 1040px;
    margin:0 auto;
    background:url("../../assets/img/joinFormImg.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .formDiv{
      margin-top: 338px;
      width: 504px;
      height: 580px;
      padding: 45px 50px;
      background: linear-gradient( 180deg, #FFFFFF 0%, #FFFFFF 100%);
      .formText{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 28px;
        color: #050829;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        display:flex;
        align-items: center;
        .xian{
          width: 2px;
          height: 26px;
          background: #3D72FE;
          margin-right: 10px;
          position: relative;
          top: 1.2px;
        }
      }
      .formSpan{
        padding-left: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #484848;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 35px;
      }
      .formBtn{
        width: 180px;
        height: 44px;
        background: linear-gradient( 121deg, #6F98FF 0%, #3C71FE 100%);
        border-radius: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
        font-style: normal;
        margin-left: 260px;
        margin-top: 30px;
      }
    }
  }
}
</style>
